import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getModelIsLocked } from '@/presence/selectors';
import CustomFields from '@/storychief/components/CustomFields';
import useStoryCustomFields from '@/stories/hooks/useStoryCustomFields';
import EntitlementTeaser from '@/entitlements/components/EntitlementTeaser';

const propTypes = {
  story: PropTypes.shape({
    id: PropTypes.number.isRequired,
    language: PropTypes.string,
  }).isRequired,
  isPreview: PropTypes.bool,
  readOnly: PropTypes.bool.isRequired,
};
const defaultProps = {
  isPreview: false,
};

function CustomFieldsBlock(props) {
  const { story, readOnly, isPreview } = props;

  const { customFields, customFieldValues, updateStoryCustomFields, isCustomFieldsInitialized } =
    useStoryCustomFields(story.id, isPreview);

  // Functions
  function handleOnUpdate(updatedValues) {
    updateStoryCustomFields(updatedValues);
  }

  // Render
  if (customFields.length === 0 || !isCustomFieldsInitialized) {
    return null;
  }

  return (
    <div id="custom-fields" className="panel panel-default">
      <div className="panel-body">
        <EntitlementTeaser entitlement="custom-fields-stories" variant="section">
          <div className="row">
            <div className="col-md-7">
              <CustomFields
                readOnly={readOnly}
                onUpdate={handleOnUpdate}
                fields={customFields}
                isDebounceFields
                values={customFieldValues}
                language={story.language}
                type="stories"
              />
            </div>
          </div>
        </EntitlementTeaser>
      </div>
    </div>
  );
}

CustomFieldsBlock.propTypes = propTypes;
CustomFieldsBlock.defaultProps = defaultProps;

function mapStateToProps(state, ownProps) {
  return {
    story: state.story,
    readOnly: ownProps.readOnly || ownProps.isPreview || getModelIsLocked(state),
  };
}

export default connect(mapStateToProps)(CustomFieldsBlock);
